import { EventType } from "@/analytics/analytics-events";
import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { setActiveMeasurement } from "@/store/measurement-tool-slice";
import { selectModeName } from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  selectCurrentUser,
  selectHasWritePermission,
} from "@/store/user-selectors";
import {
  AnnotationIcon,
  ChangeIndicatorBadge,
  ClipboxExportIcon,
  FaroButton,
  HeatmapIcon,
  OpacitySettingsIcon,
  PointCloudAnalysisIcon,
  RulerIcon,
  SendToIcon,
  SheetSaturationToggleIcon,
  cyan,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { Link, Typography } from "@mui/material";
import { ClipSceneIcon, ClipSceneSubmenu } from "./clip-scene-tool-ui";
import { ToolGroup, ToolStoreField, Toolbar } from "./toolbar";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

/**
 * Custom component to show if the clipping tool is disabled and the user is not logged in.
 *
 * @returns a text with a link to start the login process for the user.
 */
function LogInForSubVolumeTooltip(): JSX.Element {
  const { requestLogin } = useAuthContext();

  return (
    <Typography>
      Please{" "}
      <Link
        onClick={requestLogin}
        sx={{ color: "blue350", ":hover": { cursor: "default" } }}
      >
        log in
      </Link>{" "}
      to set a sub volume
    </Typography>
  );
}

/**
 * @returns A toolbar providing buttons to activate tools to use within the canvas
 *  Visibility of the tools is controlled through the ui store slice
 */
export function CanvasToolbar(): JSX.Element | null {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUser);
  const currentMode = useAppSelector(selectModeName);

  const measureActive =
    useAppSelector(selectActiveTool) === ToolName.measurement;

  function onMeasurementToolClick(): void {
    Analytics.track(
      measureActive
        ? EventType.disableMeasurementMode
        : EventType.enableMeasurementMode,
    );
    if (measureActive) dispatch(setActiveMeasurement(undefined));
  }

  const sendToActive = useAppSelector(selectActiveTool) === ToolName.sendTo;
  function onSendToToolClick(): void {
    Analytics.track(
      sendToActive ? EventType.disableSendToMode : EventType.enableSendToMode,
    );
  }

  const desaturation =
    useAppSelector(selectActiveTool) === ToolName.desaturateSheet;
  function onDesaturationToolClick(): void {
    Analytics.track(
      desaturation
        ? EventType.turnDesaturationOff
        : EventType.turnDesaturationOn,
    );
  }

  const isClipSceneActive =
    useAppSelector(selectActiveTool) === ToolName.clipScene;
  function onClipSceneToolClick(): void {
    Analytics.track(
      isClipSceneActive ? EventType.hideClippingBox : EventType.showClippingBox,
    );
  }

  const disableScaling = useDisableCaptureTreeAlignment();

  const tools: ToolGroup[] = [
    {
      key: "tools",
      tools: [
        {
          name: ToolName.sendTo,
          icon: <SendToIcon />,
          tooltip: {
            default: "SendTo",
            disabled:
              "SendTo tool is not available with this data type. Switch to point cloud",
          },
          onClick: onSendToToolClick,
        },
        {
          name: ToolName.measurement,
          icon: <RulerIcon />,
          tooltip: {
            default: "Measure",
            isInteractive: currentMode === "sheet",
            disabled:
              currentMode === "sheet" ? (
                <>
                  Measurements are possible only on floor images with a scale.
                  {disableScaling ? (
                    <>
                      <br />
                      {compatibilityMessage}
                    </>
                  ) : (
                    <>
                      Define the scale first.
                      <FaroButton
                        variant="ghost"
                        size="s"
                        dark
                        onClick={() => dispatch(changeMode("floorscale"))}
                        sx={{ color: cyan[500] }}
                      >
                        Set Scale
                      </FaroButton>
                    </>
                  )}
                </>
              ) : (
                "Measurements are possible on 360° photos containing depth information or on the point cloud"
              ),
          },
          onClick: onMeasurementToolClick,
        },
        {
          name: ToolName.analysis,
          icon: <PointCloudAnalysisIcon />,
          tooltip: {
            default: "Point cloud analysis",
            disabled:
              "Analysis tool is not available. It can only be used with point cloud data.",
          },
        },
        {
          name: ToolName.annotation,
          icon: <AnnotationIcon />,
          tooltip: {
            default: "Create Annotation",
            isInteractive: currentMode === "sheet",
            disabled: <TooltipForDisabledAnnotation />,
          },
        },
        {
          name: ToolName.clipping,
          icon: <ClipboxExportIcon />,
          tooltip: {
            default: "Define Sub Volume",
            isInteractive: !currentUser,
            disabled: <TooltipForDisabledClippingBox />,
          },
        },
        {
          name: ToolName.clipScene,
          icon: <ClipSceneIcon />,
          tooltip: {
            default: "Clip scene",
            disabled:
              "Clip scene tool is not available when 360° Photo is selected",
          },
          subMenu: (
            <ClipSceneSubmenu
              onReset={() => {
                Analytics.track(EventType.resetClippingBox);
              }}
              onAutoClipped={() => {
                Analytics.track(EventType.applyAutoClippingBox);
              }}
            />
          ),
          onClick: onClipSceneToolClick,
        },
        {
          name: ToolName.desaturateSheet,
          icon: <SheetSaturationToggleIcon />,
          tooltip: {
            default: "Toggle desaturation of sheet",
            disabled:
              "Toggling the desaturation state of the sheet is not available at the moment.",
          },
          onClick: onDesaturationToolClick,
        },
      ],
    },
    {
      key: "settings",
      storeField: ToolStoreField.activeSettingsMenu,
      tools: [
        {
          name: ToolName.opacity,
          icon: <OpacityIcon />,
          tooltip: {
            default: "Opacity",
          },
        },
        {
          name: ToolName.heatmap,
          icon: <HeatmapIcon />,
          tooltip: {
            default: "Heatmap comparison",
          },
        },
      ],
    },
  ];
  return <Toolbar toolGroups={tools} />;
}

/**
 * @returns Icon for the opacity toolbar that is showing a badge dot while any opacity is applied to the models
 */
function OpacityIcon(): JSX.Element {
  const { areDefaultOpacities, objectsOpacity } =
    useTransparencySettingsContext((s) => s);

  return (
    <ChangeIndicatorBadge invisible={areDefaultOpacities(objectsOpacity)}>
      <OpacitySettingsIcon />
    </ChangeIndicatorBadge>
  );
}

/** @returns the an appropriate disabled message tooltip for Clipping box tool */
function TooltipForDisabledClippingBox(): JSX.Element {
  const currentUser = useAppSelector(selectCurrentUser);

  const hasWritePermission = useAppSelector(selectHasWritePermission);
  if (currentUser) {
    if (!hasWritePermission) {
      return <>Define Sub Volume is only accessible with edit permissions</>;
    }
    return (
      <>
        Define Sub Volume tool is not available. You can only use it with Point
        Cloud data
      </>
    );
  }
  return <LogInForSubVolumeTooltip />;
}

/** @returns the an appropriate disabled message tooltip for Annotation tool */
function TooltipForDisabledAnnotation(): JSX.Element {
  const dispatch = useAppDispatch();

  const currentMode = useAppSelector(selectModeName);
  const disableScaling = useDisableCaptureTreeAlignment();

  const hasWritePermission = useAppSelector(selectHasWritePermission);
  if (!hasWritePermission) {
    return <>Annotation creation is only available for Project Editors</>;
  }

  if (disableScaling) {
    // Will be removed when https://faro01.atlassian.net/browse/SWEB-5087 is resolved
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{compatibilityMessage}</>;
  }

  if (currentMode === "sheet") {
    return (
      <>
        Annotations are possible only on floor images with a scale. Define the
        scale first.
        <FaroButton
          variant="ghost"
          size="s"
          dark
          onClick={() => dispatch(changeMode("floorscale"))}
        >
          Set Scale
        </FaroButton>
      </>
    );
  }
  return <>Annotation creation will be available soon!</>;
}
