import { RootState } from "@/store/store";
import {
  AutodeskIntegrationData,
  ProcoreIntegrationData,
} from "@faro-lotv/ielement-types";

/**
 * @returns The list of enabled workspace integrations
 * @param state current app state
 */
export function selectWorkspaceIntegrations(state: RootState): string[] {
  if (state.integrations.workspaceIntegrations) {
    return state.integrations.workspaceIntegrations.map((integration) => {
      return integration.provider;
    });
  }
  return [];
}

/**
 * @returns true if the user can write data to the given integration
 * @param state current app state
 * @param integration the integration to check
 */
export function selectIntegrationCanWriteData(
  state: RootState,
  integration: string,
): boolean {
  if (state.integrations.workspaceIntegrations === undefined) {
    return false;
  }

  const index = state.integrations.workspaceIntegrations.findIndex(
    (currentIntegration) => currentIntegration.provider === integration,
  );
  if (index !== -1) {
    return state.integrations.workspaceIntegrations[index].scopes.includes(
      "data:write",
    );
  }

  return false;
}

/**
 * @returns The list of enabled project integrations
 * @param state current app state
 */
export function selectProjectIntegrations(state: RootState): string[] {
  const integrations: string[] = [];
  if (state.integrations.projectIntegrations) {
    if (state.integrations.projectIntegrations.autodesk) {
      integrations.push("autodesk");
    }
    if (state.integrations.projectIntegrations.procore) {
      integrations.push("procore");
    }
  }
  return integrations;
}

/**
 * @returns The associated integration's data or null if the integration is not enabled
 * @param state current app state
 * @param integration the integration to get the data for
 */
export function selectProjectIntegrationData(
  state: RootState,
  integration: string,
): AutodeskIntegrationData | ProcoreIntegrationData | null | undefined {
  if (state.integrations.projectIntegrations) {
    switch (integration) {
      case "autodesk":
        return state.integrations.projectIntegrations.autodesk;
      case "procore":
        return state.integrations.projectIntegrations.procore;
      default:
        throw new Error(`Unknown integration type: ${integration}`);
    }
  }
  return null;
}
