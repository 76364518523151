import {
  ElementIcon,
  ElementIconType,
  useElementIcon,
} from "@/components/ui/icons";
import { TreeNode, TreeNodeProps } from "@/components/ui/tree/tree-node";
import { TreePopUp } from "@/components/ui/tree/tree-popup";
import { TreeNodeDisabledReason } from "@/components/ui/tree/tree-types";
import { OverlayBadgeData, useOverlayBadge } from "@/hooks/use-overlay-bagde";
import { selectWizardElementToAlignId } from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  CircularProgress,
  FaroButton,
  blue,
  useCheckForOverflow,
} from "@faro-lotv/flat-ui";
import {
  GUID,
  IElementBase,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  TreeData,
  selectIElement,
  selectIsSubtreeLoading,
} from "@faro-lotv/project-source";
import { Badge, Box, Typography } from "@mui/material";
import { MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import { ReferenceTreeFolderIds } from "./align-wizard-reference-tree";

/**
 * @returns the icon associated with an Element if available or a simple folder icon
 * @param id id of the folder in case of the folder node; undefined for other cases
 * @param nodeElement the node attached project element if available
 * @param directParentType the type/hints of the node parent if available
 */
function useReferenceElementIcon(
  id?: ReferenceTreeFolderIds | GUID,
  nodeElement?: Pick<IElementBase, "id" | "type" | "typeHint">,
  directParentType?: Pick<IElementBase, "type" | "typeHint">,
): ElementIconType {
  const elementIcon = useElementIcon(nodeElement, directParentType);

  if (id) {
    switch (id) {
      case ReferenceTreeFolderIds.modelFolderId: {
        return ElementIconType.CadModel;
      }
      case ReferenceTreeFolderIds.cloudsFolderId: {
        return ElementIconType.DataSession;
      }
      case ReferenceTreeFolderIds.sheetsFolderId: {
        return ElementIconType.MapIcon;
      }
    }
  }

  return elementIcon;
}

/**
 * @returns the component to use for each node in the AlignmentWizards reference tree.
 */
export function AlignmentWizardReferenceTreeNode({
  node,
  style,
  isSelectable = true,
}: TreeNodeProps<TreeData>): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  const icon = useReferenceElementIcon(
    node.data.element ? undefined : node.data.id,
    node.data.element,
    node.data.directParent,
  );

  const overlayBadgeData = useOverlayBadge(node.id, node.isClosed);

  const [isDisabled, disabledReason] = useMemo(() => {
    let disabledReason: TreeNodeDisabledReason | undefined;

    if (
      node.children === null &&
      (node.id === ReferenceTreeFolderIds.cloudsFolderId ||
        node.id === ReferenceTreeFolderIds.sheetsFolderId ||
        node.id === ReferenceTreeFolderIds.modelFolderId)
    ) {
      disabledReason = TreeNodeDisabledReason.notElementAvailable;
    } else if (elementToAlign && isIElementAreaSection(elementToAlign)) {
      disabledReason =
        node.id === ReferenceTreeFolderIds.sheetsFolderId
          ? TreeNodeDisabledReason.notCompatibleReference
          : undefined;
    }
    return [!!disabledReason, disabledReason];
  }, [elementToAlign, node.children, node.id]);

  // Since react-arborist has no built-in "disabled" state this callback prevents items from being selected
  const onNodeClick = useCallback(
    (ev: MouseEvent) => {
      if (isDisabled) {
        ev.stopPropagation();
      }
    },
    [isDisabled],
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const showTooltip = useCallback(() => setIsPopupOpen(true), []);
  const hideTooltip = useCallback(() => setIsPopupOpen(false), []);

  const boxRef = useRef<HTMLDivElement>();

  return (
    <Box
      ref={boxRef}
      component="div"
      style={{ ...style, height: "100%" }}
      onClick={onNodeClick}
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
    >
      <TreePopUp
        parentRef={boxRef}
        title={node.data.label}
        isTooltipEnabled={hasOverflown || isDisabled}
        disabledReason={disabledReason}
        open={isPopupOpen}
      >
        <TreeNode<TreeData> node={node} isSelectable={isSelectable}>
          <ReferenceNodeLabel
            iconType={icon}
            overlayBadgeData={overlayBadgeData}
            name={node.data.label}
            element={node.data.element}
            onMouseEnterName={(ev) => checkForOverflow(ev.currentTarget)}
            isDisabled={isDisabled}
            isRecommended={
              node.data.id === ReferenceTreeFolderIds.modelFolderId &&
              !isDisabled
            }
          />
        </TreeNode>
      </TreePopUp>
    </Box>
  );
}

type ReferenceNodeLabelProps = {
  /** Specific Icon to show for the current tree node */
  iconType: ElementIconType;

  /** Type of this IElement */
  element?: TreeData["element"];

  /** Name of the IElement represented by the node to be displayed as a tree node text label */
  name: TreeData["label"];

  /** Contains data necessary to render the overlay icon on the node */
  overlayBadgeData?: OverlayBadgeData;

  /** Method to call when the mouse enters the element containing the name */
  onMouseEnterName(event: React.MouseEvent): void;

  /** True if the node is disabled; In case if node disabled it's not grayed out, but mouse clicks on that node are ignored  */
  isDisabled: boolean;

  /** if true then "Recommended" label will be displayed for the node */
  isRecommended?: boolean;
};

/** @returns the label component used, for each node, in the tree with the node's icon, name and context menu */
function ReferenceNodeLabel({
  iconType,
  name,
  element,
  overlayBadgeData,
  onMouseEnterName,
  isRecommended,
}: ReferenceNodeLabelProps): JSX.Element {
  const isLoading = useAppSelector((state) =>
    element ? selectIsSubtreeLoading(element.id)(state) : false,
  );

  return (
    <>
      <Badge
        badgeContent={overlayBadgeData?.badgeContent}
        color={
          overlayBadgeData ? overlayBadgeData.badgeBackgroundColor : "warning"
        }
        overlap="circular"
        invisible={!overlayBadgeData}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {isLoading ? (
          <CircularProgress size="1.125em" />
        ) : (
          <ElementIcon icon={iconType} sx={{ fontSize: "1.125em" }} />
        )}
      </Badge>

      <Typography
        ml={1}
        fontSize="0.875em"
        flexGrow={1}
        noWrap
        onMouseEnter={onMouseEnterName}
      >
        {name}
      </Typography>

      {isRecommended && (
        <FaroButton
          aria-label="recommended_selection"
          variant="primary"
          size="xs"
          sx={{
            fontFamily: "Open Sans",
            textTransform: "none",
            background: blue[500],
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: 1,
            px: 0.5,
            py: 0.25,
            minWidth: 0,
          }}
          disabled
        >
          RECOMMENDED
        </FaroButton>
      )}
    </>
  );
}
